import React from "react"
import Map from "src/components/map"
import styled from "styled-components"
import { Flex, Text, Image } from "src/styled"
import {
    Description,
    Title,
    Section,
    Wrapper,
    // } from 'src/modules/main/components'
} from "../../../src/modules/Main/components"

import emailImage from "src/assets/images/email.png"
import phoneImage from "src/assets/images/phone.png"
import facebookImage from "src/assets/images/facebook.png"
import instagramImage from "src/assets/images/instagram.png"
import { useSelector } from "react-redux"
import { devices } from "src/theme/device"
import theme from "src/theme"

const DescriptionText = styled(Text)`
    font-size: 18px;
    color: ${theme.colors.secondary};

    ${devices.laptop} {
        font-size: 14px;
    }
`

const SocialLink = styled.a`
    cursor: pointer;
    img {
        width: 50px;
    }

    ${devices.laptop} {
        img {
            width: 30px;
        }
    }
`
const MapContainer = styled(Flex)`
    flex: 1;
    ${devices.laptop} {
        height: 300px;
        margin-top: 20px;
    }
`

const InfoWrapper = styled(Flex)`
    flex-direction: row;

    ${devices.laptop} {
        flex-direction: column-reverse;
    }
`
const Info = styled(Flex)`
    flex: 1;
    justify-content: center;
    flex-direction: column;
`

export default function Contact() {
    const { ZONE_A, ZONE_B } = useSelector(state => state.prices)
    return (
        <Section>
            <Wrapper id="contact">
                <Title> Kontakt </Title>
                <Description>
                    Dokonaj zamówienia online z dostawą lub odbiorem osobistym w
                    naszym lokalu na Kolejowej 45.
                </Description>

                <InfoWrapper>
                    <MapContainer>
                        <Map />
                    </MapContainer>

                    <Info>
                        <Text fontSize={{ _: 20, lg: 24 }} pb={20}>
                            Godziny otwarcia:
                        </Text>

                        <DescriptionText>
                            {" "}
                            Pn - czwartek: 12:00-22:00
                        </DescriptionText>
                        <DescriptionText>Piątek 12:00-23:00</DescriptionText>
                        <DescriptionText>Sobota 13:00-23:00</DescriptionText>
                        <DescriptionText>
                            {" "}
                            Niedziela 13:00-22:00{" "}
                        </DescriptionText>

                        <DescriptionText pt={20}>
                            Godziny przyjmowania zamówień w dostawie:
                        </DescriptionText>

                        <DescriptionText>
                            Poniedziałek-czwartek do 20:20
                        </DescriptionText>
                        <DescriptionText>Piątek do 21:20</DescriptionText>
                        <DescriptionText>
                            Sobota, niedziela do 20:20
                        </DescriptionText>
                        <br />
                        <DescriptionText>
                            Zamówienia przyjmujemy od godziny 9:00.
                        </DescriptionText>
                        <DescriptionText>
                            Lunche - dostępne pn-pt: 12:00-15:00
                        </DescriptionText>

                        <br />
                        <DescriptionText>
                            Dostawa możliwa jest dla zamówień powyżej 50 zł.
                        </DescriptionText>
                        <DescriptionText>
                            Koszt dostawy to w zależności od lokalizacji{" "}
                            {ZONE_A} zł lub {ZONE_B} zł.
                        </DescriptionText>
                        <br />

                        <Text fontWeight={"bold"} fontSize={{ _: 14, lg: 24 }}>
                            W razie pytań znajdziesz nas:
                        </Text>

                        <Flex
                            justifyContent={"space-around"}
                            width={{ _: 1, lg: 1 / 2 }}
                            mx={"auto"}
                            mt={25}
                        >
                            <SocialLink
                                href={
                                    "https://www.instagram.com/pracowniasushiwola/"
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Image src={instagramImage} />
                            </SocialLink>

                            <SocialLink
                                href={
                                    "https://www.facebook.com/PracowniaSushii"
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Image src={facebookImage} />
                            </SocialLink>

                            <SocialLink
                                target="_blank"
                                rel="noopener noreferrer"
                                href={"tel:+48222209915"}
                            >
                                <Image src={phoneImage} />
                            </SocialLink>

                            <SocialLink
                                target="_blank"
                                rel="noopener noreferrer"
                                href={"mailto:Biuro@sushiwola.pl"}
                            >
                                <Image src={emailImage} />
                            </SocialLink>
                        </Flex>
                    </Info>
                </InfoWrapper>
            </Wrapper>
        </Section>
    )
}
