import styled from "styled-components"
import { Header, Text, Box } from "src/styled"
import { devices } from "src/theme/device"

export const Title = styled(Header)`
    font-size: 40px;
`

export const Description = styled(Text)`
    font-size: 24px;
    padding: 20px 0;
    color: ${({ theme }) => theme.colors.secondary};
    ${devices.laptop} {
        font-size: 20px;
    }
`

export const Wrapper = styled(Box)`
    padding: 50px 0;
    height: 100%;
`

export const Section = styled(Box)`
    padding: 20px 0;
    margin: 0 50px;
    text-align: center;
    ${devices.laptop} {
        margin: 0 10px;
    }
`
