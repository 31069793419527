import React from "react"
import styled from "styled-components"

import { Flex, Text } from "src/styled"
import regulationPDF from "src/assets/pdf/Regulamin_sklepu.pdf"
import politikaPDF from "src/assets/pdf/Polityka_Prywatności.pdf"
import { devices } from "src/theme/device"

const Container = styled(Flex)`
    padding: 10px;
    flex-direction: column;
    background: black;
    color: white;
    font-size: 15px;

    ${devices.laptop} {
        font-size: 12px;
    }
`

const Link = styled.a`
    color: white;
    background: none;
    padding: 20px 30px;
    cursor: pointer;
    text-align: center;

    ${devices.tablet} {
        padding: 5px 0;
    }
`

const Wrapper = styled(Flex)`
    justify-content: space-between;
    width: 70%;
    margin: 0 auto;
    border-bottom: 3px solid white;

    ${devices.tablet} {
        width: 100%;
        flex-direction: column;

        > div {
            justify-content: space-between;
        }
    }
`
const CopyrightContainer = styled(Flex)`
    justify-content: space-between;
    text-align: center;
    margin-top: 20px;
`

export default function Footer() {
    function onScroll() {
        const element = document.getElementById("presentation").offsetTop

        window.scrollTo({
            top: element,
            behavior: "smooth",
        })
    }

    return (
        <Container>
            <Wrapper>
                <Flex>
                    <Link onClick={onScroll}>Strona Główna</Link>

                    <Link
                        href={
                            "https://cms-psushi.s3.eu-central-1.amazonaws.com/Alergeny+Pracownia+Sushi.pdf"
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Lista Alergenów
                    </Link>
                </Flex>

                <Flex>
                    <Link
                        href={regulationPDF}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Regulamin
                    </Link>

                    <Link
                        href={politikaPDF}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Polityka Prywatności
                    </Link>
                </Flex>
            </Wrapper>

            <CopyrightContainer>
                <Text>Copyright &copy; Pracownia Sushi 2024</Text>
                <Text>Created with &#10084; in Warsaw by Blesu.</Text>
            </CopyrightContainer>
        </Container>
    )
}
