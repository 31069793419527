import React from "react"
import styled from "styled-components"
import { Grid, Box, Text } from "src/styled"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHeart, faComment } from "@fortawesome/free-solid-svg-icons"
import { devices } from "src/theme/device"

const StyledGrid = styled(Grid)`
    margin: 20px 0;
    grid-template-columns: repeat(auto-fit, 300px);
    grid-gap: 20px;
    grid-auto-rows: 300px;
    justify-content: center;
    ${devices.tablet} {
        grid-template-columns: repeat(auto-fill, 1fr);
    }
`

const Description = styled(Box)`
    background: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 300px;
    width: 300px;
    opacity: 0;
    margin: auto;
    transition: 0.1s ease-in-out;
    display: flex;
    color: white;
    justify-content: center;
    align-items: center;
    svg {
        font-size: 20px;
    }
    p {
        margin: 0 10px;
    }
`

const ImageWrapper = styled(Box)`
    overflow: hidden;
    position: relative;
    &:hover {
        ${Description} {
            opacity: 1;
        }
    }
    img {
        border-radius: 5px;
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
`

const Gallery = ({ images }) => {
    return (
        <StyledGrid>
            {images &&
                images.map((imageData, index) => (
                    <ImageWrapper key={index}>
                        <img src={imageData.mediaURL} />

                        <a
                            target="_blank"
                            rel="noreferrer"
                            href={imageData.permalink}
                        >
                            <Description></Description>
                        </a>
                    </ImageWrapper>
                ))}
        </StyledGrid>
    )
}

export default Gallery
