import React from "react"
import { Loading } from "src/components/common"
import isDomAvailable from "src/utils/isDomAvailable"
import styled from "styled-components"

const API_KEY = "AIzaSyBGlkJavo9moXPClC1t_2Cgg2bOfMAdMKo"
const PLACE_ID = "ChIJa3ckfAzLHkcR3IuEATPyRQM"

const MapIFrame = styled.iframe`
    width: 100%;
    height: 100%;
    border: 0;
`

const Map = () => {
    if (!isDomAvailable()) return <Loading />

    return (
        //Check https://developers.google.com/maps/documentation/embed/start, for how I got the PLACE_ID
        <MapIFrame
            title="maps"
            src={`https://www.google.com/maps/embed/v1/place?q=place_id:${PLACE_ID}&key=${API_KEY}`}
        ></MapIFrame>
    )
}

export default Map
