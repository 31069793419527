import React from "react"
import styled from "styled-components"
import { navigate } from "@reach/router"
import { Flex, Box, Header, Button } from "src/styled"

import { Section } from "./components"

import { devices } from "src/theme/device"
import backgroundImage from "src/assets/images/new/zdjecie_glowne.jpg"
import { background } from "styled-system"
import { isSafari } from "react-device-detect"
export const BackgroundImage = styled(Box)`
    width: 100%;
    height: 100%;
    background-size: cover;
    background-image: ${({ src }) => `url(${src})`};
    position: relative;
    box-shadow: -40px 40px 10px #e2e0e0;
    ${devices.tablet} {
        width: 90%;
        margin: auto;
        height: 300px;
    }
`
const WelcomeBox = styled(Box)`
    position: absolute;
    right: 10%;
    top: 20%;
    padding: 30px 20px;
    border-radius: 5px;
    box-shadow: 1px 2px 2px 0 rgba(0, 0, 0, 0.1), 0 1px 5px 0 rgba(0, 0, 0, 0.1);
    background: rgba(255, 255, 255, 0.9);
    width: 400px;

    ${devices.tablet} {
        width: 100%;
        position: relative;
        right: 0;
        top: 0;
        border-radius: 0;
        background: none;
        box-shadow: none;
    }
`

const Wrapper = styled(Flex)`
    position: relative;
    height: 80vh;
    max-width: 1400px;
    margin: auto;
    flex: 1;
    flex-direction: row;

    ${devices.tablet} {
        flex-direction: column;
    }
`
const Message = styled(Header)`
    padding: 10px 0;
    font-size: 32px;

    ${devices.tablet} {
        font-size: 24px;
    }
`
//
export default function Presentation() {
    return (
        <Section id={"presentation"}>
            <Flex>
                <Wrapper>
                    <BackgroundImage src={backgroundImage} borderRadius={10} />
                    <WelcomeBox>
                        <Message>Zamów na dowóz lub odbierz w lokalu.</Message>

                        <Button
                            width={1}
                            variant={"primary"}
                            onClick={() => navigate("/menu")}
                        >
                            ZAMÓW ONLINE
                        </Button>
                    </WelcomeBox>
                </Wrapper>
            </Flex>
        </Section>
    )
}
